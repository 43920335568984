<template>
	<div class="total drag" v-if="infoList" v-draw id="CfbParameter3">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数03_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				返料优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="context">
			<div class="up">
				<div class="shadow shadow1 flex">
					<div class="column1">C05-PZ1</div>
					<div class="column3" @click="toIpt(infoList.MCSCWKZ.FLPSPJB,'C05-PZ1','FLPSPJB','MCSCWKZ')">
						{{infoList.MCSCWKZ.FLPSPJB}}
					</div>
				</div>
			</div>
			<div class="middle flex">
				<div class="one">
					<div class="left">
						<div class="shadow shadow2 float1">
							<div class="flex">
								<div class="column1">C01-JBSP</div>
								<div class="column2"
									@click="toIpt(infoList.MCSCWKZ.CWSP_B1,'C01-JBSP','CWSP_B1','MCSCWKZ')">
									{{infoList.MCSCWKZ.CWSP_B1}}
								</div>
							</div>
							<div class="flex">
								<div class="flex">
									<div class="column1">C01-CWK</div>
									<div class="column2"
										@click="toIpt(infoList.MCSCWKZ.KCWSPBC,'C01-CWK','KCWSPBC','MCSCWKZ')">
										{{infoList.MCSCWKZ.KCWSPBC}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">C01-SJSP</div>
									<div class="column3" @click="toCompon(2,'CWSP_SJ','MCSCWKZ','CWSP_SJVV1','床温实际控制点')"
										@dblclick="Cclick(infoList.MCSCWKZ.CWSP_SJ,'CWSP_SJ','MCSCWKZ','CWSP_SJVV1')">
										{{infoList.MCSCWKZ.CWSP_SJ}}
									</div>
								</div>
							</div>
						</div>
						<div class="shadow shadow2 float2">
							<div class="flex">
								<div class="column1">F04-CWSPH</div>
								<div class="column2"
									@click="toIpt(infoList.MCSFENG.ECF_CWMAXH,'F04-CWSPH','ECF_CWMAXH','MCSFENG')">
									{{infoList.MCSFENG.ECF_CWMAXH}}
								</div>
							</div>
							<div class="flex">
								<div class="flex">
									<div class="column1">F04-CWSPL</div>
									<div class="column2"
										@click="toIpt(infoList.MCSFENG.ECF_CWMAXL,'F04-CWSPL','ECF_CWMAXL','MCSFENG')">
										{{infoList.MCSFENG.ECF_CWMAXL}}
									</div>
								</div>
								<div class="flex">
									<div class="column1">F04-CWSP</div>
									<div class="column3" @click="toCompon(2,'SP','MCSRPQ__p__RSF0E','RSF0E_SPVV1','RSF0E设定值')"
										@dblclick="Cclick(infoList.MCSRPQ__p__RSF0E.SP,'SP','MCSRPQ__p__RSF0E','RSF0E_SPVV1')">
										{{infoList.MCSRPQ__p__RSF0E.SP}}
									</div>
								</div>
							</div>
						</div>
						<div class="shadow shadow1 flex float3">
							<div class="column1">C02-CWJZ</div>
							<div class="column3" @click="toCompon(2,'CWJZ','MCSCWKZ','CWJZVV1','床温均值')"
								@dblclick="Cclick(infoList.MCSCWKZ.CWJZ,'CWJZ','MCSCWKZ','CWJZVV1')">
								{{infoList.MCSCWKZ.CWJZ}}
							</div>
						</div>
					</div>
				</div>
				<div class="two flex">
					<div class="button float1">
						<div class="button_word" @click="toIpt(infoList.MCSCWKZ.FLYCC,'按钮','FLYCC','MCSCWKZ')">
							{{infoList.MCSCWKZ.FLYCC ? "区间控制" : "点控制"}}
						</div>
					</div>
					<div class="buttonx float2" :style="{
							background:infoList.MCSXK__p__XK09 && !infoList.MCSXK__p__XK09.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK09
							? toDetail(1, 'XK09', 'MCSXK__p__XK09', '', '床温返料调节'): ''">
						X
					</div>
					<div class="shadow shadow3 float3">
						<div class="flex">
							<div class="column1">C04-CWZ</div>
							<div class="column3" @click="toCompon(2,'CWJZ_Z','MCSYCL','CWJZ_ZVV1','左侧床温均值')"
								@dblclick="Cclick(infoList.MCSYCL.CWJZ_Z,'CWJZ_Z','MCSYCL','CWJZ_ZVV1')">
								{{infoList.MCSYCL.CWJZ_Z}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">C04-CWY</div>
							<div class="column3" @click="toCompon(2,'CWJZ_Y','MCSYCL','CWJZ_YVV1','右侧床温均值')"
								@dblclick="Cclick(infoList.MCSYCL.CWJZ_Y,'CWJZ_Y','MCSYCL','CWJZ_YVV1')">
								{{infoList.MCSYCL.CWJZ_Y}}
							</div>
						</div>
					</div>
					<div class="buttonr float4" :style="{
							background:infoList.MCSRPQ__p__RSF01 && !infoList.MCSRPQ__p__RSF01.SP
						    ? '#2AFC30'
						    : 'red',}" @click="infoList.MCSRPQ__p__RSF01
							? toDetail(3,'RSF01','MCSRPQ__p__RSF01','','返料平衡软伺服'): ''">
						R
					</div>
					<div class="box float5">
						<div class="column3">0.5</div>
						<div class="button button_word" @click="toIpt(infoList.MCSCWKZ.HLHWTY,'按钮','HLHWTY','MCSCWKZ')">
							{{infoList.MCSCWKZ.HLHWTY ? "投用" : "切除"}}
						</div>
						<div class="column3">-0.5</div>
					</div>

				</div>
				<div class="three">
					<div class="shadow shadow1 float1 flex">
						<div class="column1">C05-A0</div>
						<div class="column3" @click="toCompon(2,'KF_FL1_B','MCSSOURCE','KF_FL1_BVV1','1#返料调节反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_FL1_B,'KF_FL1_B','MCSSOURCE','KF_FL1_BVV1')">
							{{infoList.MCSSOURCE.KF_FL1_B}}
						</div>
					</div>
					<div class="buttonx float2" :style="{
									background:
									infoList.MCSMAN__p__MAN11.RM == 1  || infoList.MCSMAN__p__MAN12.RM  == 1 ? '#2AFC30' : 'red'
									}" @click="toCompon(0,'CFB_MANFL',2)">
						A
					</div>
					<div class="shadow shadow1 float3 flex">
						<div class="column1">C05-A0</div>
						<div class="column3" @click="toCompon(2,'KF_FL2_B','MCSSOURCE','KF_FL2_BVV1','2#返料调节反馈')"
							@dblclick="Cclick(infoList.MCSSOURCE.KF_FL2_B,'KF_FL2_B','MCSSOURCE','KF_FL2_BVV1')">
							{{infoList.MCSSOURCE.KF_FL2_B}}
						</div>
					</div>
				</div>
			</div>
			<div class="down">
				<div class="shadow shadow1 flex">
					<div class="column1">C05-PZ2</div>
					<div class="column3" @click="toIpt(infoList.MCSCWKZ.FLPSPJB2,'C05-PZ2','FLPSPJB2','MCSCWKZ')">
						{{infoList.MCSCWKZ.FLPSPJB2}}
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import manyManual from "@/components/manyManual.vue"; //手操器组件
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			manyManual
		},
		data: () => {
			return {
				chName: '',
				manyManual: false,
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter03', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter3 {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/CfbBoiler/para031_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 1.5vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 14.5vw;
			margin-top: 1.8vh;
		}

		.main {
			width: 53vw;
			height: 2vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.context {
			width: 51vw;
			height: 31vh;
			margin-top: 6vh;
			margin-left: 1vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 0vw;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				font-size: 0.85vw;
				line-height: 2.5vh;
			}

			.shadow1 {
				width: 8vw;
				height: 2.5vh;

				.column1 {
					width: 5vw;
					color: #8aeaff;
					margin-left: 0.3vw;
				}

				.column2 {
					width: 2vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.up {
				margin-left: 36vw;
			}

			.shadow2 {
				width: 18vw;
				height: 5.6vh;

				.column1 {
					width: 5vw;
					color: #8aeaff;
					margin-left: 0.5vw;
				}

				.column2 {
					width: 3vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 3vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.shadow3 {
				width: 8.6vw;
				height: 5.6vh;

				.column1 {
					width: 5vw;
					color: #8aeaff;
					margin-left: 0.5vw;
				}

				.column2 {
					width: 2.5vw;
					color: #00ffb4;
					text-align: right;
				}

				.column3 {
					width: 2.5vw;
					color: #00e4ff;
					text-align: right;
				}
			}

			.one {
				.float1 {
					margin-left: 0vw;
					margin-top: 3.5vh;
				}

				.float2 {
					margin-left: 0vw;
					margin-top: 0.8vh;
				}

				.float3 {
					margin-left: 10vw;
					margin-top: 4.7vh;
				}
			}

			.two {
				width: 25.3vw;
				height: 25.9vh;
				background-image: url("~@/assets/images/CfbBoiler/para031.png");
				background-size: 100% 100%;

				.button {
					width: 3vw;
					height: 2vh;
					border: 1px solid #22fff7;
					box-sizing: border-box;
					background-color: #22fff7;
					text-align: center;

					.button_word {
						font-size: 0.8vw;
						line-height: 1.8vh;
						color: black;
					}
				}

				.box {
					font-size: 1vh;
					line-height: 2vh;
					margin-left: 2vw;

					.column3 {
						width: 2vw;
						font-size: 1vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.float1 {
					margin-left: 0.9vw;
					margin-top: 8.7vh;
				}

				.float2 {
					margin-left: 1.1vw;
					margin-top: 8.5vh;
				}

				.float3 {
					margin-left: 1.6vw;
					margin-top: 7vh;
				}

				.float4 {
					margin-left: 1.5vw;
					margin-top: 9.7vh;
				}

				.float5 {
					margin-left: 2.7vw;
					margin-top: 7.5vh;
				}
			}

			.three {
				.float1 {
					margin-left: 0vw;
					margin-top: 2.7vh;
				}

				.float2 {
					margin-left: 2vw;
					margin-top: 6.9vh;
				}

				.float3 {
					margin-left: 0vw;
					margin-top: 5.5vh;
				}
			}

			.down {
				margin-left: 36vw;
			}
		}
	}
</style>
